<template>
  <transition name="fade">
    <div class="export-center" v-click-outside="onClickOutside">
      <table border="0" cellspacing="0" cellpadding="0">
        <tr>
          <th>{{ $t('export_category') }}</th>
          <th>{{ $t('export_filename') }}</th>
          <th>{{ $t('export_expiration_time') }}</th>
          <th>{{ $t('export_file_size') }}</th>
          <th></th>
        </tr>
        <tr v-for="(item, idx) in exportList" :key="`${item.id}_${idx}`">
          <td>{{ $t(getCategory(item)) }}</td>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="true" :content="getFilename(item.description)" placement="top">
            <td>{{ getFilename(item.description) }}</td>
          </el-tooltip>
          <td>{{ formatExpiredTime(item.expiredTime) }}</td>
          <td>{{ formatBytes(item.size) }}</td>
          <td>
            <div class="action">
              <div v-if="item.deletedTime" class="btn deleting">
                {{ $t('export_status_deleting') }}
              </div>
              <button
                v-else
                class="btn"
                :class="{ download: item.status === 1, failed: item.status === -1, exporting: item.status === 0 }"
                @click="downloadReport(item.status, item.url)"
              >
                <img v-if="item.status === 1" src="@/assets/icons/file_download.svg" alt="">{{ getStatusLabel(item.status) }}
              </button>
              <button class="btn delete" :class="{ disabled: item.deletedTime }" :disabled="item.deletedTime" @click="deleteReport(item)">
                <img src="@/assets/icons/feather-trash.svg" alt="">
              </button>
            </div>
          </td>
        </tr>
        <tr v-for="(i, index) in nEmpty" :key="index" class="empty">
          <td></td>
        </tr> 
      </table>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import vClickOutside from 'v-click-outside'

import { apiGetBoviaLprEventsReports, apiDeleteBoviaLprEventsReport } from '@/api/index.js' // 歷史車牌事件
import { apiGetBoviaLprPerformanceReport, apiDeleteBoviaLprPerformanceReport } from '@/api/index.js' // 車牌績效報表
import { apiGetAuditsReports, apiDeleteAuditsReport } from '@/api/index.js' // 車牌績效報表
import { PermissionLevel } from '@/config/permissions.js' // 權限階層

export default {
  name: 'ExportCenter',
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('history', ['exportList', 'defaultCustomFilename']),
    ...mapGetters(['timezone']),
    nEmpty() {
      return this.exportList.length >= 5 ? 0 : 5 - this.exportList.length
    },
    apis() {
      return [
        {
          key: 'lprEventsReports',
          get: apiGetBoviaLprEventsReports,
          delete: apiDeleteBoviaLprEventsReport,
          allow:
            this.permissionV2.lprEventAccess > PermissionLevel.L0 ||
            this.permissionV2.lprEventAdvancedAccess > PermissionLevel.L0
        },
        {
          key: 'lprPerformanceReport',
          get: apiGetBoviaLprPerformanceReport,
          delete: apiDeleteBoviaLprPerformanceReport,
          allow: this.permissionV2.lprReport > PermissionLevel.L0
        },
        {
          key: 'auditsReports',
          get: apiGetAuditsReports,
          delete: apiDeleteAuditsReport,
          allow: this.permissionV2.audit > PermissionLevel.L0
        }
      ]
    }
  },
  created() {
    this.getExportList()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    ...mapMutations('history', ['updateExportMap', 'updateExportList', 'updateShowExportPanel']),
    fmtExportList(list, api) {
      return list.map((rpt) => {
        return { ...rpt, ...{ api } }
      })
    },
    async getExportList() {
      try {
        for (let api of this.apis) {
          if (!api.allow) continue

          // const res = await apiGetBoviaLprEventsReports()
          // this.updateExportList(res.data)
          const res = await api.get()
          if (res.status === 200) {
            const tmpList = this.fmtExportList(res.data, api)
            const data = new Object({ [api.key]: tmpList })
            this.updateExportMap(data)
            this.updateExportList()
          }
        }
        // console.log(`[getExxportList] exportList:`, this.exportList)

        // 10秒後再重複執行getExportList
        this.timer = setTimeout(() => {
          this.getExportList()
        }, 10000)
      } catch (err) {
        console.log(err)
      }
    },
    getCategory(item) {
      switch (item.api.key) {
        case 'lprEventsReports':
          return item.advanced === 1
            ? 'export_category_advanced' // 歷史事件-進階查詢
            : 'export_category_basic' // 歷史事件-一般查詢
        case 'lprPerformanceReport':
          return 'export_category_performance' // 績效查詢
        case 'auditsReports':
          return 'export_category_audit' // 稽核查詢
      }
    },
    getFilename(description) {
      // 下載的檔名為description + .zip
      return `${description}.zip`
    },
    formatExpiredTime(expiredTime) {
      return moment(expiredTime).tz(this.timezone).format('MM/DD HH:mm')
    },
    getStatusLabel(status) {
      let statusLabel = ''
      switch (status) {
        case -1:
          statusLabel = this.$t('export_status_fail') /* 匯出失敗 */
          break
        case 0:
          statusLabel = this.$t('export_status_exporting') /* 匯出中 */
          break
        case 1:
          statusLabel = this.$t('export_status_download') /* 下載 */
          break
      }
      return statusLabel
    },
    downloadReport(status, url) {
      if (status !== 1) return
      let link = document.createElement('a')
      document.body.appendChild(link)
      link.style = 'display: none;'
      link.href = url
      link.click()
      document.body.removeChild(link)
    },
    async deleteReport(item) {
      // await apiDeleteBoviaLprEventsReport(item.id)
      await item.api.delete(item.id)
      // 刪除完重新取exportList
      // const res = await apiGetBoviaLprEventsReports()
      const res = await item.api.get()
      if (res.status === 200) {
        const tmpList = this.fmtExportList(res.data, item.api)
        const data = new Object({ [item.api.key]: tmpList })
        this.updateExportMap(data)
        this.updateExportList()
      }
    },
    formatBytes(bytes, decimals = 2) {
      if(!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${ parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) } ${sizes[i]}`
    },
    onClickOutside() {
      this.updateShowExportPanel(false)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.export-center {
  position: fixed;
  padding: 8px 0px;
  left: 50px;
  bottom: 40px;
  border-radius: 0 6px 6px 0;
  border: 8px solid #4A5C78;
  background: #151B35;
  z-index: 5;
}

table {
  table-layout: fixed;
  width: 1016px;
  height: 358px;
}

th {
  color: #ffffff80;
  font-size: px2rem(20);
  font-weight: 300;
  line-height: 29px;
  text-align: left;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

td {
  min-width: 100px;
  height: 63px;
  color: #ffffff;
  font-size: px2rem(20);
  font-weight: 300;
  padding: 10px 0;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* box-sizing: border-box; */
}

tr:not(:first-child):not(.empty):hover {
  border-radius: 3px;
  background: #4A5C78;
}

th:nth-child(1), td:nth-child(1) {
  width: 220px;
  padding: 8px 12px 4px 16px;
}

th:nth-child(2), td:nth-child(2) {
  width: 253px;
  padding: 8px 28px 4px 16px;
}

th:nth-child(3), td:nth-child(3) {
  width: 152px;
}

th:nth-child(4), td:nth-child(4) {
  width: 120px;
}

th:nth-child(5), td:nth-child(5) {
  width: 202px;
  padding-right: 12px;
}

.action {
  display: flex;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  padding: 7px 16px;
  font-size: px2rem(20);
  cursor: pointer;
}

.btn:not(.delete) {
  min-width: 135px;
  padding: 7px 12px;
  margin-right: 12px;
}

.btn:not(.delete) img {
  margin-right: 6px;
}

.btn:hover {
  opacity: 0.85;
}

.btn img {
  width: 24px;
  height: 24px;
}

.download {
  background: #8CC24D;
}

.delete {
  background: #F94144;
}

.failed {
  background: #F94144;
  opacity: 0.5;
  cursor: not-allowed;
}

.exporting, .deleting {
  background: #6E7D93;
  border: 1px solid #6E7D93;
  animation: bgchange 1s infinite;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes bgchange {
  0% {
    background-color: #19223b;
  }
  50% {
    background-color: #6E7D93;
  }
  100% {
    background-color: #19223b;
  }
}
</style>